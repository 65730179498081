<template>
  <div style="padding: 20px">
  <div class="handle-box">
      <el-button type="primary" class="handle-del mr10" @click="Title = true">新增使用说明</el-button>
      <el-input v-model="query.title" placeholder="标题" style="width: 160px"></el-input>
      <el-select v-model="query.roleId" placeholder="请选择" style="width: 160px">
        <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name">
        </el-option>
      </el-select>
      <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
      <el-button type="info" icon="Search" @click="reset">重置</el-button>
    </div>
    <el-table :data="tableData" border class="table" ref="multipleTable" :expand-row-keys="expands"
      header-cell-class-name="table-header" @selection-change="handleSelectionChange" @expand-change="openhang"
      :row-key="getRowKeys">
      <!-- 展开列 -->
      <el-table-column type="expand">
        <template #default="scope">
          <div style="display:flex;flex-wrap: wrap;">
            <div class="item" style="" v-for="(item, index) in contents" :key="index">
              <div>
                {{ item.title }}
              </div>
              <div>
                <!--展开行编辑按钮-->
                <el-button type="text" size="small" @click="handleEditss(index, scope.row)">编辑</el-button>
                <!--展开行删除按钮-->
                <el-button type="text" size="small" @click="handleDeletes(index, scope.row)" circle>删除</el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" align="center"></el-table-column>
      <el-table-column prop="name" label="图片"></el-table-column>
      <el-table-column prop="content" label="备注"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template #default="scope">

          <!--添加按钮-->
          <el-button type="text" size="small" @click="handleEdit(scope.id, scope.row)">添加</el-button>
          <!-- 编辑 -->
          <!-- <el-button type="text" size="small">编辑</el-button> -->
          <!--删除按钮-->
          <el-button el-button type="text" size="small" @click="handleDelete(index, scope.row)" circle>
            删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
        :page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
    </div>
  </div>
  <!-- 弹出标题框 -->
  <el-dialog v-model="Title" width="500px">
    <el-form :model="querys" :rules="addTit">
      <el-form-item prop="title" label="标题">
        <el-input v-model="querys.title" placeholder="请输入主标题内容内容"></el-input>
      </el-form-item>
      <div style="display:flex;">
        <el-form-item prop="sort" label="排序" width="180">
          <el-input v-model.number="querys.sort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="选择">
          <el-select v-model="querys.type" placeholder="请选择" style="width: 160px">
            <el-option value="1" key="1" label="小程序">
            </el-option>
            <el-option value="2" key="2" label="WEB端">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="图标">
        <el-upload class="upload" :http-request="uploadIcon" :before-upload="beforeAvatarUpload"
          :on-remove="handleRemove"
          :class="{ uoloadSty: thisImage == null ? true : false, disUoloadSty: thisImage != null ? true : false }" :limit="1"
          list-type="picture-card">
         <el-button icon="Plus" text />
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="Title = false">取消</el-button>
      <el-button type="primary" @click="helpAdd">确定</el-button>
    </template>
  </el-dialog>
  <!-- 新增弹出富文本 -->
  <el-dialog v-model="bb" title="新增说明" width="1100px">
    <template #footer>
      <div class="flex">
        <el-select v-model="querys.xcxRoleId" placeholder="请选择" style="width: 160px">
          <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name">
          </el-option>
        </el-select>
        <el-input v-model="querys.stitle" placeholder="请输入二级标题内容内容"></el-input>
      </div>

      <div class="websiteEditorElem">
        <div id="websiteEditorElem" style="height:500px;background: #ffffff;text-align: left"></div>
      </div>
      <el-button @click="bb = false">取消</el-button>
      <el-button type="primary" @click="addContent">确定</el-button>
    </template>
  </el-dialog>

  <div>
  </div>
  <!-- 编辑弹出富文本 -->
  <el-dialog v-model="editVisibles" title="编辑说明" width="1100px">
    <template #footer>
      <el-form style="margin-top: -50px" :model="querys" :rules="addTit">
        <div class="flex">
          <el-select v-model="querys.xcxRoleId" placeholder="请选择" style="width: 160px">
            <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name">
            </el-option>
          </el-select>
          <el-input v-model="querys.stitle" placeholder="请输入二级标题内容内容"></el-input>
        </div>
        <div class="websiteEditorElem">
          <div id="websiteEditorElems" style="background: #ffffff;"></div>
          <el-button type="warning" @click="editVisibles = false">取消</el-button>
          <el-button type="success" @click="edits">提交</el-button>
        </div>
      </el-form>
    </template>

  </el-dialog>

</template>
<script>
import E from 'wangeditor'
import { roles } from "@/api/coach";
import { page, helpManualAdd, helpManualItem, uploadImage, uploadVideo, edit, Delete } from '@/api/helpManual'
export default {
  name: 'text',
  data() {
    return {
      bb: false,
      editVisibles: false,
      expands: [], //只展开一行放入当前行id
      getRowKeys(row) {
        return row.id;
      },
      colorList:[],
      sTitle: '',
      contents: '',
      tableData: [],
      Title: false,
      pageTotal: '',
      phoneEditor: '',
      token: '',
      query: {
        roleId: "",
        pageIndex: 1,
        pageSize: 10,
        title: '',
      },
      addTit: {
        sort: [
          { required: true, message: 'Sort是必须的' },
          { type: 'number', message: 'Sort必须为数值' },
        ]
      },
      querys: {
        sort: '',
        stitle: '',
        type: '',
        title: "",
        content: "",
        pid: '',
        id: '',
        xcxRoleId: '',
      },
      search: {
        xcxRoleId: '',
        title: ''
      },
      editVisible: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.colorList = ['#303133','#606266','#909399','#A8ABB2','#C0C4CC','#CDD0D6','#D4D7DE','#DCDFE6','#E4E7ED','#EBEEF5','#F2F6FC','#409EFF','#67C23A','#E6A23C','#F56C6C','#909399']
    this.getPage()
    this.token = localStorage.getItem('token')
    roles().then(res => {
      if (res.code == 200) {
        this.roles = res.data
        //  console.log(this.roles);
      } else {
        this.$message.error("获取角色数据失败");
      }
    })
  },
  methods: {
    /**
    * 删除图片
    */
    handleRemove() {
      this.thisImage = null;
    },
    /**
   * 图片限制
   */
    beforeAvatarUpload(file) {
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      const one = file.limit === 1;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (one > 2) {
        this.$message.error('上传图片只能1张!');
      }
      return (isJPG || isPNG);
    },
    //上传Icon
    uploadIcon(file) {
      const data = new FormData()
      data.append(
        'multipartFile', file.file
      )
      data.append(
        'flag', 'coach'
      )
      data.append(
        'fileName', file.file.name
      )
      uploadImage(data).then(res => {
        console.log(res.data);
      })
    },
    //重置
    reset() {
      this.query.title = ''
      this.query.roleId = ''
      this.getPage()
    },
    //删除按钮
    handleDelete(a, row) {
      // console.log(row.id);
      this.$confirm("确定要删除吗?", "提示", {
        type: "warning"
      }).then(() => {
        Delete(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.getPage()
          } else {
            this.$message.error("删除失败");
          }
        })
      })

    },
    //展开行删除
    handleDeletes(a) {
      // console.log(a, row);
      // console.log(this.contents[a].id);
      this.$confirm("确定要删除吗?", "提示", {
        type: "warning"
      }).then(() => {
        Delete(this.contents[a].id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.getPage()
          } else {
            this.$message.error("删除失败");
          }
        })
      })
    },
    //编辑富文本
    edits() {
      // console.log(this.querys);
      this.querys.title = this.querys.stitle
      edit(this.querys).then(res => {
        console.log(res);
      })
      this.editVisibles = false
    },
    //展开每行获取数据
    openhang(row, expandedRows) {
    // console.log(row);
    let that = this;
    that.contents = ''
    // console.log(expandedRows);
    //只展开一行
    if (expandedRows.length) {
      //说明展开了
      that.expands = [];
      if (row) {
        that.expands.push(row.id); //只展开当前行id
      }
    } else {
      //说明收起了
      that.expands = [];
    }
    // let orderInfo = {order:{}};
    helpManualItem(row.id).then(res => {
      res.data.forEach(item => {
        item.content = this.tohtml(item.content)
      });
      this.contents = res.data
    })
  },
    tohtml(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },
    //行内编辑
    handleEdit(a, b) {
      this.bb = true
      this.querys.pid = b.id
      this.editVisible = true;
      this.showModule();
    },
    handleEditss(a) {
      this.querys = this.contents[a]
      this.querys.stitle = this.contents[a].title
      console.log(this.contents[a].content);
      this.phoneEditor1.txt.html('<p>' + this.contents[a].content + '</p>')
      this.editVisibles = true
    },
    //添加内容
    addContent() {
      this.querys.title = this.querys.stitle
      this.querys.content = this.phoneEditor.txt.html();
      helpManualAdd(this.querys).then(res => {
        if (res.code == 200) {
          this.bb = false
          this.getPage()
        }
      })
      this.querys = {
        stitle: '',
        type: '',
        title: "",
        content: "",
        pid: '',
        id: '',
        xcxRoleId: '',
      }
      this.editVisible = false
    },
    //添加标题
    helpAdd() {
      // console.log(this.querys);
      this.ss = false
      helpManualAdd(this.querys).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.getPage()
        }
      })
    },
    //获取分页
    getPage() {
      page(this.query).then(res => {
        if (res.code == 200) {

          this.tableData = res.data.records
          this.pageTotal = res.data.records.length
        }

      })
    },

    handleSearch() {
      this.getPage()
    },
    handleSelectionChange() {
      console.log(1);
    },
    getTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },
    showModule(){
      // 绑定div上的IDwebsiteEditorElem
      this.phoneEditor = new E('#websiteEditorElem')
      this.phoneEditor1 = new E('#websiteEditorElems')
      this.colorList.forEach(item=>{
        this.phoneEditor.config.colors.push(item)
        this.phoneEditor1.config.colors.push(item)
      })
      // 获取输入的内容
      this.phoneEditor.config.onchange = (html) => {
        // 打印输入的内容
        console.log(html);
      }

      this.phoneEditor1.config.fontSizes = {
        'x-small': { name: '10px', value: '1' },
        'small': { name: '11px', value: '2' },
        'normal': { name: '12px', value: '3' },
        'large': { name: '13px', value: '4' },
        'x-large': { name: '14px', value: '5' },
        'xx-large': { name: '15px', value: '6' },
        'xxx-large': { name: '16px', value: '7' },
      }

      this.phoneEditor1.config.onchange = (html) => {
        // 打印输入的内容
        this.querys.content = html
      }
      // 上传图片到服务器，base64形式
      this.phoneEditor.config.uploadImgShowBase64 = false
      this.phoneEditor.config.customUploadImg = function (file, insertImgFn) {
        // console.log(file[0]);
        const data = new FormData()
        let fileName = 'helpManual/'
        data.append(
            'multipartFile', file[0]
        )
        data.append(
            'fileName', fileName
        )
        data.append(
            'flag', 'helpManual'
        )

        uploadImage(data).then(res => {
          // console.log(res);
          let url = res.data
          insertImgFn(url)
        })
      },
          //改变此方法才可以进行自定义上传，不输入地址
          this.phoneEditor.config.customUploadVideo = (files, insertImgFn) => {
            // console.log(files[0]);
            const data = new FormData()
            // let fileName = 'helpManual'
            data.append(
                'multipartFile', files[0]
            )
            data.append(
                'fileName', files[0].name
            )
            data.append(
                'flag', 'helpManual'
            )
            uploadVideo(data).then(res => {
              // console.log(res);
              let url = res.data
              insertImgFn(url)
            })
          }
      this.phoneEditor1.config.uploadImgShowBase64 = false
      this.phoneEditor1.config.customUploadImg = function (file, insertImgFn) {
        // console.log(file[0]);
        const data = new FormData()
        let fileName = 'helpManual'
        data.append(
            'multipartFile', file[0]
        )
        data.append(
            'fileName', fileName
        )
        data.append(
            'flag', 'helpManual'
        )

        uploadImage(data).then(res => {
          // console.log(res);
          let url = res.data
          insertImgFn(url)
        })
      },
          this.phoneEditor1.config.customUploadVideo = (files, insertImgFn) => {
            // console.log(files[0]);
            const data = new FormData()
            // let fileName = 'helpManual'
            data.append(
                'multipartFile', files[0]
            )
            data.append(
                'fileName', files[0].name
            )
            data.append(
                'flag', 'helpManual'
            )
            uploadVideo(data).then(res => {
              // console.log(res);
              let url = res.data
              insertImgFn(url)
            })
          }
      // 隐藏网络图片
      this.phoneEditor.config.showLinkImg = false
      this.phoneEditor1.config.showLinkImg = false
      // 创建一个富文本编辑器
      this.phoneEditor.create()
      this.phoneEditor1.create()
      // 富文本内容
      this.phoneEditor.txt.html()
      this.phoneEditor1.txt.html()
    }
  },
}

</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}


.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.bdbottom {
  border-bottom: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  margin-top: 20px;
}

.w-e-text {
  z-index: 21 !important;
}
.item{
  display:flex;
  width: 450px;
  font-size: 14px;
  margin: 10px;
  height: 50px;
  line-height: 50px;
  background-color: #F2F6FC;
  justify-content: space-between;
  border-radius: 12px;
  padding: 0 20px;
}
font[size="1"] {
    font-size: 10px;
}
font[size="2"] {
    font-size: 11px;
}
font[size="3"] {
    font-size: 12px;
}
font[size="4"] {
    font-size: 13px;
}
font[size="5"] {
    font-size: 14px;
}
font[size="7"] {
    font-size: 15px;
}
font[size="6"] {
    font-size: 16px;
}

</style>
